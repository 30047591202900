<template>
  <el-main>
    <div style="margin-bottom: 20px">
      <el-button type="primary" size="small" @click="$router.push('/service/beautyAddService')">添加服务</el-button>
      <el-button type="primary" size="small" @click="startClassificationAdministration">管理分类</el-button>
    </div>
    <!-- 搜索表单 -->
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="服务名称：">
        <el-input v-model="ruleForm.service_name" size="small" placeholder="请输入服务名称"></el-input>
      </el-form-item>
      <el-form-item label="服务分类：">
        <el-select size="small" v-more="loadMoreType" v-model="ruleForm.classify_id" filterable remote :remote-method="getServiceClassify" placeholder="请选择" class="classify_select">
          <el-option v-for="item in serviceClassify" :key="item.id" :label="item.classify_name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否上架：">
        <el-select size="small" v-model="ruleForm.status">
          <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small" @click="getServiceList(1)">搜索</el-button>
        <el-button size="small">导出</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <!-- 渲染表格 -->
    <el-table :data="serviceList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column align="center" width="100px">
        <template #header>
          <el-checkbox v-model="allCheck" @change="allCheckChange" label="全选"></el-checkbox>
        </template>
        <template v-slot="{ row }">
          <el-checkbox @change="serviceCheckChange(row)" v-model="row.checkFlag"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="排序" align="center" width="100px">
        <template v-slot="{ row }">
          <el-input class="borderNone" @change="changeServiceData(row, 'sort')" size="small" type="number" v-model="row.sort"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="服务ID" align="center" width="80px"></el-table-column>
      <el-table-column label="服务主图" align="center" width="80px">
        <template v-slot="{ row }">
          <el-image style="width: 60px; height: 60px" :src="row.cover_picture"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="service_name" label="服务名称" align="center"></el-table-column>
      <el-table-column prop="classify_name" label="服务分类" align="center"></el-table-column>
      <el-table-column label="商品价格" align="center" width="150px">
        <template v-slot="{ row }">
          <el-input class="borderNone" @change="changeServiceData(row, 'service_price')" size="small" type="number" v-model="row.service_price"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="销量" align="center" width="100px">
        <template v-slot="{ row }">
          {{ Number(row.virtual_sales) + Number(row.sales) }}
        </template>
      </el-table-column>
      <el-table-column label="上/下架" align="center" width="80px">
        <template v-slot="{ row }">
          <el-button @click="changeServiceData(row, 'status')" :type="!row.status ? 'primary' : ''" size="small">{{ row.status ? '下架' : '上架' }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="200px">
        <template v-slot="{ row }">
          <el-button type="text" @click="$router.push('/service/beautyEditService?id=' + row.id)">编辑</el-button>
          <el-button type="text" @click="deleteService([row.id])">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="serviceTotal" :page="servicePage" :pageNum="serviceRows" @updatePageNum="updateServiceData">
      <template #batch>
        <div>
          <el-checkbox v-model="allCheck" @change="allCheckChange" label="全选"></el-checkbox>
          <el-select style="margin: 0 20px" size="small" v-model="allSelect">
            <el-option v-for="item in batchList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-button size="small" @click="manyOperation">确定</el-button>
        </div>
      </template>
    </Paging>
    <!-- 管理分类弹框 -->
    <el-dialog title="管理分类" :visible.sync="classificationAdministrationFlag" width="1000px">
      <div>
        <el-button type="primary" size="small" @click="startOperationClassification(null)">+ 添加分类</el-button>
        <el-table :data="classificationList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
          <template slot="empty">
            <No />
          </template>
          <el-table-column prop="classify_name" label="分类名称" align="center"></el-table-column>
          <el-table-column prop="sort" label="排序" align="center"></el-table-column>
          <el-table-column label="操作" align="center">
            <template v-slot="{ row }">
              <el-button type="text" size="small" @click="startOperationClassification(row)">编辑</el-button>
              <el-button type="text" size="small" @click="deleteClassification(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <Paging :total="classificationTotal" :page="classificationPage" :pageNum="classificationRows" @updatePageNum="updateClassificationData"></Paging>
      </div>
    </el-dialog>

    <!-- 添加编辑分类弹框 -->
    <el-dialog title="提示" :visible.sync="operationClassificationFlag" width="350px" center>
      <el-form :model="operationClassificationData" status-icon :rules="operationClassificationDataRule" ref="operationClassification" label-width="100px" class="demo-ruleForm">
        <el-form-item label="分类名称：" prop="classify_name">
          <el-input v-model="operationClassificationData.classify_name" placeholder="请输入分类名称" size="small"></el-input>
        </el-form-item>
        <el-form-item label="排序：" prop="sort">
          <el-input v-model.number="operationClassificationData.sort" placeholder="请输入排序数值" size="small"></el-input>
        </el-form-item>
        <div class="btnBox">
          <el-button size="small" @click="operationClassificationFlag = false">取 消</el-button>
          <el-button size="small" type="primary" @click="operationClassificationSureBtn">确 定</el-button>
        </div>
      </el-form>
    </el-dialog>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import _ from 'lodash';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      classificationAdministrationFlag: false,
      classificationList: [],
      classificationPage: 1,
      classificationRows: 10,
      classificationTotal: 0,
      operationClassificationFlag: false,
      operationClassificationData: { classify_name: '', sort: '' },
      operationClassificationDataRule: {
        classify_name: [{ required: true, message: '分类名称不能为空', trigger: 'blur' }],
        sort: [{ required: true, message: '排序数值不能为空', trigger: 'blur' }],
      },
      ruleForm: {
        classify_id: 0,
        service_name: '',
        status: 0,
      },
      // 搜索时使用的分类列表
      serviceClassify: [],
      serviceClassifyName: '',
      serviceClassifyPage: 1,
      serviceClassifyRows: 10,
      serviceClassifyTotal: 0,
      statusList: [
        { id: 0, name: '全部' },
        { id: 2, name: '上架' },
        { id: 1, name: '下架' },
      ],
      serviceList: [],
      chooseServiceList: [],
      servicePage: 1,
      serviceRows: 10,
      serviceTotal: 0,
      allCheck: false,
      allSelect: null,
      batchList: [
        { id: 1, name: '上架' },
        { id: 2, name: '下架' },
        { id: 3, name: '删除' },
      ],
    };
  },
  // 自定义指令
  directives: {
    more: {
      bind(el, binding) {
        const SELECTWRAP_DOM = el.querySelector('.classify_select .el-scrollbar .el-select-dropdown__wrap');
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      },
    },
  },
  created() {
    this.getClassificationList();
    this.getServiceClassify();
    this.getServiceList();
  },
  methods: {
    // 清空列表的搜索条件
    cancelSearch() {
      this.ruleForm = {
        classify_id: 0,
        service_name: '',
        status: 0,
      };
      this.getServiceList(1);
    },
    // 点击打开分页管理弹框
    startClassificationAdministration() {
      this.getClassificationList(1);
      this.classificationAdministrationFlag = true;
    },
    // 分类列表的分页操作
    updateClassificationData(val, status) {
      if (status == 0) {
        this.classificationRows = val;
      } else {
        this.classificationPage = val;
      }
      this.getClassificationList();
    },
    // 服务列表的分页操作
    updateServiceData(val, status) {
      if (status == 0) {
        this.serviceRows = val;
      } else {
        this.servicePage = val;
      }
      this.getServiceList();
    },
    // 获取分类列表
    getClassificationList(style) {
      if (style) this.classificationPage = 1;
      this.$axios
        .post(this.$api.beauty.service.serviceClassifyList, {
          page: this.classificationPage,
          rows: this.classificationRows,
        })
        .then(res => {
          if (res.code === 0) {
            this.classificationList = res.result.list;
            this.classificationTotal = res.result.total_number;
          }
        });
    },
    // 点击删除分类
    deleteClassification(data) {
      this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.beauty.service.delServiceClassifyList, {
            id: data.id,
          });
        })
        .then(res => {
          if (res.code === 0) {
            this.$message({
              message: `删除成功`,
              type: 'success',
              customClass: 'mzindex',
            });
            this.getClassificationList();
          } else {
            this.$message({
              message: res.msg,
              type: 'error',
              customClass: 'mzindex',
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 点击开始编辑该分类
    startOperationClassification(data) {
      if (data) {
        this.operationClassificationData = _.cloneDeep(data);
      } else {
        this.operationClassificationData = { classify_name: '', sort: '' };
      }
      this.operationClassificationFlag = true;
    },
    // 点击确认编辑或者添加服务分类
    operationClassificationSureBtn() {
      this.$refs.operationClassification.validate(valid => {
        if (valid) {
          let obj = _.cloneDeep(this.operationClassificationData);
          let url = obj.id ? this.$api.beauty.service.editServiceClassifyList : this.$api.beauty.service.addServiceClassifyList;
          this.$axios.post(url, obj).then(res => {
            if (res.code === 0) {
              if (obj.id) {
                if (this.classificationRows < 50) {
                  let res = this.classificationList.find(item => item.id === obj.id);
                  res.classify_name = obj.classify_name;
                  res.sort = obj.sort;
                } else {
                  this.getClassificationList();
                }
                this.$message({
                  message: `编辑成功`,
                  type: 'success',
                  customClass: 'mzindex',
                });
              } else {
                this.getClassificationList(1);
                this.$message({
                  message: `添加成功`,
                  type: 'success',
                  customClass: 'mzindex',
                });
              }
              this.operationClassificationFlag = false;
            } else {
              this.$message({
                message: res.msg,
                type: 'error',
                customClass: 'mzindex',
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    // 服务分类下拉框 滑动加载更多数据
    loadMoreType() {
      if (Math.ceil(this.serviceClassifyTotal / this.serviceClassifyRows) > this.serviceClassifyPage) {
        this.serviceClassifyPage++;
        this.getServiceClassify(this.serviceClassifyName, 1);
      }
    },
    // 获取服务分类
    getServiceClassify(name, is_more) {
      if (!is_more) {
        this.serviceClassifyPage = 1;
      }
      this.serviceClassifyName = name;
      this.$axios
        .post(this.$api.beauty.service.serviceClassifyList, {
          page: this.serviceClassifyPage,
          rows: this.serviceClassifyRows,
          classify_name: name,
        })
        .then(res => {
          if (res.code === 0) {
            if (is_more) {
              this.serviceClassify = this.serviceClassify.concat(res.result.list);
            } else {
              this.serviceClassify = res.result.list;
              this.serviceClassify.unshift({ classify_name: '全部', id: 0 });
            }
            this.serviceClassifyTotal = res.result.total_number;
          }
        });
    },
    // 查询服务列表
    getServiceList(style) {
      if (style) this.servicePage = 1;
      let obj = {
        page: this.servicePage,
        rows: this.serviceRows,
      };
      if (this.ruleForm.service_name) {
        obj.service_name = this.ruleForm.service_name;
      }
      if (this.ruleForm.classify_id) {
        obj.classify_id = this.ruleForm.classify_id;
      }
      if (this.ruleForm.status) {
        obj.status = this.ruleForm.status;
      }
      this.$axios.post(this.$api.beauty.service.serviceList, obj).then(res => {
        if (res.code === 0) {
          this.serviceList = res.result.list;
          this.serviceList.map(item => {
            this.$set(item, 'checkFlag', false);
          });
          // 清空选中状态
          this.chooseServiceList = [];
          this.allCheck = false;
          this.allSelect = null;
          this.serviceTotal = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 更改服务数据
    changeServiceData(data, key, is_many) {
      if (key === 'status') {
        let status = data.status ? 0 : 1;
        let ids = [data.id];
        this.$confirm(`请确定是否${data.status ? '下架' : '上架'}该服务！！！`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            return this.changeStatus(ids, status);
          })
          .then(res => {
            if (res.code == 0) {
              data.status = status;
              this.$message.success('修改成功');
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch(() => {});
      } else {
        let obj = {
          id: data.id,
        };
        if (key === 'sort') obj.sort = data.sort;
        if (key === 'service_price') obj.service_price = data.service_price;
        this.$axios.post(this.$api.beauty.service.editInfo, obj).then(res => {
          if (res.code !== 0) {
            this.$message.error(res.msg);
            this.getServiceList();
          }
        });
      }
    },
    // 服务上下架
    changeStatus(ids = [], status) {
      return this.$axios.post(this.$api.beauty.service.editStatus, {
        id: ids,
        status,
      });
    },
    // 删除服务
    deleteService(ids) {
      this.$confirm(`此操作将永久删除选中的服务！！！`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          return await this.$axios.post(this.$api.beauty.service.delService, {
            id: ids,
          });
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('删除成功');
            this.getServiceList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    // 操作全选
    allCheckChange() {
      if (this.allCheck) {
        this.serviceList.map(item => {
          item.checkFlag = true;
          this.chooseServiceList.push(item.id);
        });
      } else {
        this.serviceList.map(item => {
          item.checkFlag = false;
          this.chooseServiceList = [];
        });
      }
    },
    serviceCheckChange(data) {
      if (!data.checkFlag) {
        this.allCheck = false;
        let index = this.chooseServiceList.findIndex(item => item == data.id);
        this.chooseServiceList.splice(index, 1);
      } else {
        this.chooseServiceList.push(data.id);
        if (this.chooseServiceList.length === this.serviceList.length) this.allCheck = true;
      }
    },
    // 批量操作
    manyOperation() {
      if (this.allSelect === null) {
        this.$message.warning('请选择要进行的操作');
        return;
      }
      if (!this.chooseServiceList.length) {
        this.$message.warning('请选择要进行的操作的服务');
        return;
      }
      if (this.allSelect == 3) {
        this.deleteService(this.chooseServiceList);
      } else {
        let status = this.allSelect === 1 ? 1 : this.allSelect === 2 ? 0 : '';
        this.$confirm(`此操作将批量${this.allSelect === 1 ? '上架' : this.allSelect === 2 ? '下架' : ''}服务！！！`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            return this.changeStatus(this.chooseServiceList, status);
          })
          .then(res => {
            if (res.code == 0) {
              this.$message.success('批量操作成功');
              // 清空选中状态
              this.getServiceList();
            } else {
              this.$message.error(res.msg);
            }
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  .el-table {
    margin-top: 20px;
  }
  .addClassification {
    .classificationItem {
      display: flex;
      align-items: center;
      .classNameTitle {
        white-space: nowrap;
      }
    }
  }
  .btnBox {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .borderNone {
    /deep/ .el-input__inner {
      border-color: transparent;
    }
  }
}
</style>
